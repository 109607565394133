<template>
    <div class="page">
        <div class="conBox">
            <div class="con">
                <div class="title">巡检照片</div>
                <div class="line"></div>
                <div class="li">
                    <span class="li-title">商户门头照</span>
                    <van-uploader v-model="imgData.merchantImgPhoto" :after-read="afterReadImg"
                        :max-size="10 * 1024 * 1024" @oversize="onOversize" :max-count="1">
                    </van-uploader>
                </div>
                <div class="li">
                    <span class="li-title">收银台</span>
                    <van-uploader v-model="imgData.merchantImgCashier" :after-read="afterReadImg"
                        :max-size="10 * 1024 * 1024" @oversize="onOversize" :max-count="1">
                    </van-uploader>
                </div>
                <div class="li">
                    <span class="li-title">经营场所照</span>
                    <van-uploader v-model="imgData.merchantImgPremisesPhoto" :after-read="afterReadImg"
                        :max-size="10 * 1024 * 1024" @oversize="onOversize" :max-count="1">
                    </van-uploader>
                </div>
                <div class="li">
                    <span class="li-title">机具(收款码）</span>
                    <van-uploader v-model="imgData.merchantImgPaymentCode" :after-read="afterReadImg"
                        :max-size="10 * 1024 * 1024" @oversize="onOversize" :max-count="1">
                    </van-uploader>
                </div>
                <div class="li">
                    <span class="li-title">工商信息截图</span>
                    <van-uploader v-model="imgData.merchantImgInformation" :after-read="afterReadImg"
                        :max-size="10 * 1024 * 1024" @oversize="onOversize" :max-count="1">
                    </van-uploader>
                </div>
                <div class="li">
                    <span class="li-title">其他</span>
                    <van-uploader v-model="imgData.merchantImgOther" :after-read="afterReadImg"
                        :max-size="10 * 1024 * 1024" @oversize="onOversize" :max-count="1">
                    </van-uploader>
                </div>
            </div>
            <div class="con m10">
                <div class="title">巡检信息</div>
                <div class="line"></div>
                <div class="quBox">
                    <div class="quBox-title">1.商户机具是否移机使用？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="params.informationOne" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">2.商户机具是否被改造/加装其它设备？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="params.informationTwo" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">3.商户机具是否完整？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="params.informationThr" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">4.商户机具是否处于正常工作状态？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="params.informationFou" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">5.商户交易凭证保存是否符合规定？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="params.informationFiv" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">6.商户机具所有权是否变动？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="params.informationSix" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">7.商户是否实质性经营，是否与营业执照经营业务相符？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="params.informationSev" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">8.商户处是否有其它收单机构POS设备？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="params.informationEig" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">9.商户交易资金结算是否正常？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="params.informationNie" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">10.商户工商注册状态是否正常？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="params.informationTen" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">11.商户受益所有人是否发生变更？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="params.informationEle" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">12.支付终端附近是否有可以获取付款人密码等敏感信息的录像设施、营业场所内是否出现其他可疑收款条码？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="params.informationTwe" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">13.商户是否有语音播报器？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="params.informationFour" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">14.语音播报器是否正常？</div>
                    <div class="radioBox">
                        <van-radio-group icon-size="15px" v-model="params.informationFif" direction="horizontal">
                            <van-radio :name="1">是</van-radio>
                            <van-radio :name="2">否</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="quBox">
                    <div class="quBox-title">15.其它</div>
                    <div class="radioBox">
                        <input type="text" v-model="params.informationThi" placeholder="请输入内容" class="text_input" />
                    </div>
                </div>
            </div>
            <div class="con m10">
                <div class="title">培训信息 <span class="tips">（至少选一项）</span></div>
                <div class="line"></div>
                <div>
                    <van-checkbox-group v-model="params.trainingContent" class="check-group" direction="horizontal">
                        <van-checkbox shape="square" v-for="(item, index) in options" :key="index" :name="item.key"
                            icon-size="15px" class="check-item">
                            <span class="check-text">{{ item.value }}</span>
                        </van-checkbox>
                    </van-checkbox-group>
                </div>
            </div>
            <div class="con m10">
                <div class="title">异常情况 <span class="tips">（选填）</span></div>
                <div class="line"></div>
                <div class="abnormal">
                    <div class="title">异常</div>
                    <div class="right">
                        <van-radio-group icon-size="15px" @change="abnormalChange" v-model="params.abnormalStatus"
                            direction="horizontal" class="row-checkbox">
                            <van-radio shape="square" :name="1" class="row-square radio">无</van-radio>
                            <van-radio shape="square" :name="2" class="row-square radio">异常</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="abnormal">
                    <div class="title">业务类型</div>
                    <div class="right">
                        <van-checkbox-group class="row-checkbox" v-model="params.abnormalType"
                            :disabled="params.abnormalStatus==1">
                            <van-checkbox shape="square" icon-size="15px" :name="1" class="row-square"><span
                                    class="text">条码</span></van-checkbox>
                            <van-checkbox shape="square" icon-size="15px" :name="2" class="row-square"><span
                                    class="text">pos</span></van-checkbox>
                        </van-checkbox-group>
                    </div>
                </div>
                <div class="abnormal">
                    <div class="title">异常情况</div>
                    <div class="right">
                        <van-checkbox-group class="row-checkbox more" v-model="params.abnormalSituation"
                            :disabled="params.abnormalStatus==1">
                            <van-checkbox shape="square" icon-size="15px" :name="item.key" class="row-square"
                                v-for="(item,index) in options3" :key="index"><span
                                    class="text">{{item.value}}</span></van-checkbox>
                        </van-checkbox-group>
                    </div>
                </div>
                <div class="abnormal" v-if="params.abnormalStatus==2">
                    <div class="title">备注</div>
                </div>
                <div class="abnormal" v-if="params.abnormalStatus==2">
                    <div class="column">
                        <input type="text" v-model="params.abnormalRemark" placeholder="请输入内容" class="text_input" />
                        <van-uploader v-model="imgData.abnormalImg" :after-read="abnormalImgFun"
                            :max-size="10 * 1024 * 1024" @oversize="onOversize" :max-count="1">
                        </van-uploader>
                    </div>
                </div>
            </div>
            <!-- 位置 -->
            <div class="con m10 movablesBox paddBox">
                <div class="nameBox">
                    <div class="left" style="min-width: 80px;">
                        <span class="title">定位地址</span>
                    </div>
                    <div class="right" @click="addressShow=true">
                        <span>{{params.locationAddress==''?'请选择':params.locationAddress}}</span>
                        <img src="../../../assets/credit/jiantou.png" alt="">
                    </div>
                </div>
                <div class="clockBox">
                    <div class="circle" @click="submitBtn">
                        <div class="info">
                            <div class="clockTime">{{ currentTime }}</div>
                            <div class="clockName">提交</div>
                        </div>
                    </div>
                </div>
                <div class="clockTips"></div>
            </div>
            <!-- 占位 -->
            <div style="width: 100%;height: 80px;"></div>
        </div>
        <!-- 底部按钮 -->
        <div class="footer m10">
            <div class="box">
                <div class="btn1" @click="getBack()">返回</div>
                <div class="btn2" @click="$refs.employee.$show(serviceList, 'max')">转接</div>
                <div class="btn2" @click="goAutogra()">商户签字</div>
                <!-- <div class="btn2" @click="submitBtn">提交</div> -->
            </div>
        </div>
        <!--  员工  -->
        <employeeIndex ref="employee" @change="userChange" />
        <!-- 签名弹出层 -->
        <van-popup v-model="showEsign" round position="bottom" :style="{ height: '90%',background:'#F2F3F7' }">
            <div class="imgBox">
                <img src="../../../assets/images/festival/close.png" alt="" @click="showEsign=false">
            </div>
            <esignPage @submit="esignChange"></esignPage>
        </van-popup>
        <!-- 地址 -->
        <van-popup v-model="addressShow" round position="bottom" :style="{ height: '50%' }">
            <van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="onCancel"
                :default-index="pickerIndex" />
        </van-popup>
        <baidu-map style="display:none;" ak="uSDQM0SXJgOKGoiDmMxh5a1BR0zhoKVJ" @ready="handler"></baidu-map>
    </div>
</template>

<script>
    import { store, upload } from "@/api/contactWelcome";
    import employeeIndex from "./employeeIndex";
    import { inspectionSubmit, transfer } from "@/api/Inspection";
    import esignPage from './autograph.vue'
    import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
    import { loacationMixins } from "./mixins.js";
    export default {
        mixins: [loacationMixins],
        components: {
            employeeIndex,
            esignPage,
            BaiduMap
        },
        data() {
            return {
                fileList: [],
                radio: 1,
                showEsign: false,
                options: [
                    {
                        key: 1,
                        value: "POS及二维码交易的操作流程和要求"
                    },
                    {
                        key: 2,
                        value: "银行卡的识别、伪卡辨别、没收卡的处理及受理操作 流程"
                    },
                    {
                        key: 4,
                        value: "刷卡交易完成后，需核对持卡人签名与卡背面预留签 名是否一致"
                    },
                    {
                        key: 8,
                        value: "银行卡交易资金结算、对账数据获取方式及要求、调 账、差错处理流程"
                    },
                    {
                        key: 16,
                        value: "明确消费撤销、退货、消费调整等高风险交易的授权管理"
                    },
                    {
                        key: 32,
                        value: "POS机受理IC卡及非接触式IC卡的操作方法及注意事项"
                    },
                    {
                        key: 64,
                        value: "交易单据的保管方式与保管时限要求"
                    },
                    {
                        key: 128,
                        value: "机具及二维码的正确使用方法和简单故障排查"
                    },
                    {
                        key: 256,
                        value: "常见欺诈行为的识别及应用技巧"
                    },
                    {
                        key: 512,
                        value: "商户风险责任及风险防范知识"
                    },
                    {
                        key: 1024,
                        value: "违规行为及救济措施"
                    },
                    {
                        key: 2048,
                        value: "调单的处理要求"
                    },
                    {
                        key: 4096,
                        value: "财务处理流程、投诉、差错、争议处理要求"
                    }
                ],
                options3: [
                    {
                        key: 1,
                        value: "终端丢失/损坏"
                    }, {
                        key: 2,
                        value: "商户失联"
                    }, {
                        key: 4,
                        value: "停业"
                    }, {
                        key: 8,
                        value: "迁址"
                    }, {
                        key: 16,
                        value: "其他"
                    }],
                checkbox: [],
                params: {
                    merchantImgPhoto: '',
                    merchantImgCashier: '',
                    merchantImgPremisesPhoto: '',
                    merchantImgPaymentCode: '',
                    merchantImgInformation: '',
                    merchantImgOther: '',
                    informationOne: 2,
                    informationTwo: 2,
                    informationThr: 1,
                    informationFou: 1,
                    informationFiv: 1,
                    informationSix: 2,
                    informationSev: 1,
                    informationEig: 2,
                    informationNie: 1,
                    informationTen: 1,
                    informationEle: 2,
                    informationTwe: 2,
                    informationFour: 1,
                    informationFif: 1,
                    informationThi: '',
                    trainingContent: [],
                    abnormalStatus: 1,
                    abnormalType: undefined,
                    abnormalSituation: undefined,
                    merchantImgSig: '',
                    abnormalRemark: '',
                    abnormalImg: '',
                    locationAddress:'',
                },
                imgData: {
                    merchantImgPhoto: [],
                    merchantImgCashier: [],
                    merchantImgPremisesPhoto: [],
                    merchantImgPaymentCode: [],
                    merchantImgInformation: [],
                    merchantImgOther: [],
                    abnormalImg: []
                },
                currentType: '',
                merchantImgSig: '',
                serviceList: [],// 请选择成员
                routeId: null,
                addressShow:false,
                columns: [],
                pickerIndex: 0,

            }
        },
        mounted() {
            this.routeId = this.$route.query.id;
            const formData = JSON.parse(localStorage.getItem('formData'))
            if (formData && (formData.id==this.$route.query.id)) {
                this.params.informationOne = formData.params.informationOne;
                this.params.informationTwo = formData.params.informationTwo;
                this.params.informationThr = formData.params.informationThr;
                this.params.informationFou = formData.params.informationFou;
                this.params.informationFiv = formData.params.informationFiv;
                this.params.informationSix = formData.params.informationSix;
                this.params.informationSev = formData.params.informationSev;
                this.params.informationEig = formData.params.informationEig;
                this.params.informationNie = formData.params.informationNie;
                this.params.informationTen = formData.params.informationTen;
                this.params.informationEle = formData.params.informationEle;
                this.params.informationTwe = formData.params.informationTwe;
                this.params.informationFour = formData.params.informationFour;
                this.params.informationFif = formData.params.informationFif;
                this.params.informationThi = formData.params.informationThi;
                this.params.trainingContent = formData.params.trainingContent;
                this.params.abnormalStatus = formData.params.abnormalStatus;
                this.params.abnormalType = formData.params.abnormalType;
                this.params.abnormalSituation = formData.params.abnormalSituation;
                this.params.abnormalRemark = formData.params.abnormalRemark;
            }
        },
        beforeDestroy() {
            //页面销毁之前，缓存当前巡检id和当前配置数据
            // 下次进入的时候  判断当前id是否和缓存id一致，一致则取缓存数据赋值
            let params = {
                id: this.routeId,
                params: {
                    informationOne: this.params.informationOne,
                    informationTwo: this.params.informationTwo,
                    informationThr: this.params.informationThr,
                    informationFou: this.params.informationFou,
                    informationFiv: this.params.informationFiv,
                    informationSix: this.params.informationSix,
                    informationSev: this.params.informationSev,
                    informationEig: this.params.informationEig,
                    informationNie: this.params.informationNie,
                    informationTen: this.params.informationTen,
                    informationEle: this.params.informationEle,
                    informationTwe: this.params.informationTwe,
                    informationFour: this.params.informationFour,
                    informationFif: this.params.informationFif,
                    informationThi: this.params.informationThi,
                    trainingContent: this.params.trainingContent,
                    abnormalStatus: this.params.abnormalStatus,
                    abnormalType: this.params.abnormalType,
                    abnormalSituation: this.params.abnormalSituation,
                    abnormalRemark: this.params.abnormalRemark,
                }
            }
            localStorage.setItem('formData', JSON.stringify(params));
        },
        methods: {
            // 上传图片文件
            afterReadImg(file, type) {
                let currentType = ''
                if (this.imgData.merchantImgPhoto.includes(file)) {
                    currentType = 'merchantImgPhoto'
                }
                if (this.imgData.merchantImgCashier.includes(file)) {
                    currentType = 'merchantImgCashier'
                }
                if (this.imgData.merchantImgPremisesPhoto.includes(file)) {
                    currentType = 'merchantImgPremisesPhoto'
                }
                if (this.imgData.merchantImgPaymentCode.includes(file)) {
                    currentType = 'merchantImgPaymentCode'
                }
                if (this.imgData.merchantImgInformation.includes(file)) {
                    currentType = 'merchantImgInformation'
                }
                if (this.imgData.merchantImgOther.includes(file)) {
                    currentType = 'merchantImgOther'
                }
                file.status = "uploading";
                file.message = "上传中...";
                var formData = new FormData();
                formData.append("file", file.file);
                upload(formData).then((res) => {
                    file.status = "done";
                    this.params[currentType] = res.data.fullPath;

                });
            },
            //异常图片
            abnormalImgFun(file, type) {
                file.status = "uploading";
                file.message = "上传中...";
                var formData = new FormData();
                formData.append("file", file.file);
                upload(formData).then((res) => {
                    file.status = "done";
                    this.params.abnormalImg = res.data.path;
                });
            },
            // 文件大小
            onOversize(file) {
                this.$message.warning("文件大小不能超过 10MB");
            },
            initImageFields() {
                // 遍历 params 中的图片字段
                for (const field in this.params) {
                    if (this.params.hasOwnProperty(field) && this.params[field]) {
                        // 构建图片数据对象
                        const imageData = {
                            url: this.params[field], // 假设 params 中的值是图片的 Base64 或 URL
                            name: field, // 用于标识图片来自哪个字段
                        };
                        // 将图片数据添加到对应的 fileList 数组中
                        this.imgData[field] = [imageData];
                    }
                }
            },
            submitBtn() {
                // const hasEmptyArray = Object.entries(this.imgData)
                // .filter(([key, value]) => key !== 'merchantImgOther' && Array.isArray(value) && value.length === 0)
                // .length > 0;
                // if (hasEmptyArray) {
                //     this.$message.warning("请上传巡检照片！");
                //     return
                // }

                //this.params.abnormalStatus==2异常无需提交照片  信息
                if (this.params.abnormalStatus != 2) {
                    if (this.imgData.merchantImgPhoto.length == 0) {
                        this.$message.warning("请上传商户门头照！");
                        return
                    }
                    //培训内容
                    if (this.params.trainingContent.length == 0) {
                        this.$message.warning("请选择培训信息！");
                        return
                    }
                    // 商户签字
                    if (this.params.merchantImgSig == '') {
                        this.$message.warning("请签名确认！");
                        return
                    }
                }
                let params = {
                    ...this.params,
                    id: this.$route.query.id
                }
                inspectionSubmit(params).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('提交成功')
                        this.$router.back();
                    }
                })
            },
            //签字
            goAutogra() {
                this.showEsign = true
            },
            getBack() {
                this.$router.back();
            },
            esignChange(row) {
                this.params.merchantImgSig = row
                this.showEsign = false
            },
            // 选择员工
            userChange(data, info) {
                let employeeId = data.map(i => i.id)[0]
                //走转接流程
                let params = {
                    id: this.$route.query.id,
                    employeeId: employeeId
                }
                transfer(params).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('已提交转接')
                    }
                })
            },
            //异常选择
            abnormalChange(e) {
                if (e == 1) {//状态变更为无，需要将异常情况重置
                    this.params.abnormalType = undefined
                    this.params.abnormalSituation = undefined
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/.van-radio__label {
        font-weight: 400;
        font-size: 14px;
        color: #000000;
    }

    /deep/.van-checkbox__label {
        display: flex;
        align-items: center;
    }

    .page {
        width: 100%;
        padding: 20px 0px;
        background: #F2F3F7;
        min-height: 100vh;

        .conBox {
            width: 100%;
            padding: 0px 13px;
        }

        .m10 {
            margin-top: 10px;
        }

        .con {
            background: #FFFFFF;
            border-radius: 5px;
            width: 100%;
            padding: 16px 12px;

            .title {
                font-weight: bold;
                font-size: 17px;
                color: #000000;

                .tips {
                    font-weight: 400;
                    font-size: 14px;
                    color: #9B9B9B;
                }
            }

            .line {
                margin-top: 13px;
                width: 100%;
                border-top: 1px solid #E8E8E8;
            }

            .li {
                display: flex;
                flex-direction: column;

                .li-title {
                    font-weight: 400;
                    font-size: 15px;
                    color: #000000;
                    margin: 15px 0px;
                }
            }

            .quBox {
                margin-top: 25px;

                .quBox-title {
                    font-weight: 400;
                    font-size: 14px;
                    color: #000000;
                }

                .radioBox {
                    margin-top: 20px;

                    .text_input {
                        font-weight: 400;
                        font-size: 14px;
                        color: #000000;
                        width: 100%;
                        background: #FFFFFF;
                        border-radius: 4px;
                        border: 1px solid #BBBBBB;
                        padding: 5px;
                    }
                }
            }

            .check-group {
                display: flex;
                flex-direction: column;
                margin-top: 10px;

                .check-item {
                    margin: 10px 0;

                    .check-text {
                        font-weight: 400;
                        font-size: 14px;
                        color: #000000;
                    }
                }
            }

            .abnormal {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 9px 0;

                .title {
                    font-weight: bold;
                    font-size: 14px;
                    color: #000000;
                }

                .right {
                    width: 70%;

                    .row-checkbox {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        .row-square {
                            flex-basis: calc(50% - 5px);
                            /* 减去间距的一半，假设两边各留2.5px间距 */
                            box-sizing: border-box;

                            /* 确保内边距和边框被包含在宽度内 */
                            .text {
                                font-weight: 400;
                                font-size: 14px;
                                color: #000000;
                            }
                        }

                        .row-square:nth-child(2n+3) {
                            margin-top: 10px;
                            /* 为奇数位置的元素添加底部外边距 */
                        }

                        .radio {
                            margin-right: 0 !important;
                        }

                    }
                }

                .column {
                    width: 100%;

                    .text_input {
                        font-weight: 400;
                        font-size: 14px;
                        color: #000000;
                        width: 100%;
                        background: #FFFFFF;
                        border-radius: 4px;
                        border: 1px solid #BBBBBB;
                        padding: 5px;
                        margin-bottom: 15px;
                    }
                }
            }
        }
        .movablesBox {
            padding: 0px 20px;
            background: #FFFFFF;
            border-radius: 5px;

            .line {
                border-top: 1px solid #F5F5F5;
            }
            .line-bom{
                border-bottom: 1px solid #F5F5F5;
            }
            .nameBox {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 12px 0;

                .left {
                    .xing {
                        font-weight: bold;
                        font-size: 17px;
                        color: #CD0005;
                        margin-right: 5px;
                    }

                    .title {
                        font-weight: bold;
                        font-size: 17px;
                        color: #000000;
                    }

                    .remakeInput {
                        border: none;
                        width: 100%;
                        font-weight: 400;
                        font-size: 14px;
                        color: #9B9B9B;
                        text-align: right;
                    }

                    .tips {
                        font-weight: 400;
                        font-size: 14px;
                        color: #9B9B9B;
                    }
                }

                .right {
                    display: flex;
                    align-items: center;

                    span {
                        font-weight: 400;
                        font-size: 14px;
                        color: #000000;
                    }

                    img {
                        width: 6px;
                        height: 10px;
                        margin-left: 6px;
                    }
                }

            }

            .clockBox {
                margin-top: 24px;
                width: 100%;
                display: flex;
                justify-content: center;

                .circle {
                    width: 118px;
                    height: 118px;
                    position: relative;
                    background: #3975C6;
                    border: 8px solid #CDE3FF;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .info {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        text-align: center;
                        z-index: 2;

                        .clockTime {
                            font-size: 19px;
                            font-weight: bold;
                            color: #FFFFFF;
                        }

                        .clockName {
                            font-size: 16px;
                            font-weight: 500;
                            color: #FFFFFF;
                        }
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        top: -8px;
                        left: -8px;
                        width: calc(100% + 16px);
                        height: calc(100% + 16px);
                        border: 8px solid #CDE3FF;
                        border-radius: 50%;
                        animation: wave 2s linear infinite;
                        z-index: 1;
                    }
                }
            }

            .clockTips {
                width: 100%;
                text-align: center;
                font-weight: 400;
                font-size: 11px;
                color: #9B9B9B;
                margin-top: 25px;
            }

            @keyframes wave {
                0% {
                    box-shadow: 0 0 0 0 #CDE3FF;
                }

                50% {
                    box-shadow: 0 0 0 8px #CDE3FF;
                }

                100% {
                    box-shadow: 0 0 0 0 #CDE3FF;
                }
            }
        }

        .paddBox {
            margin-top: 10px;
            padding-bottom: 20px;
        }
        .footer {
            position: fixed;
            z-index: 999;
            left: 0;
            bottom: 0px;
            width: 100%;
            padding: 15px;
            padding-bottom: 25px;
            background: #FFFFFF;
            box-shadow: 0px 1 3px 0px rgba(0, 0, 0, 0.26);

            .box {
                background: #FFFFFF;

                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-around;

                .btn1 {
                    padding: 6px 20px;
                    background: #F2F3F7;
                    border-radius: 5px;
                    font-weight: 500;
                    font-size: 15px;
                    color: #9B9B9B;
                    text-align: center;
                }

                .btn2 {
                    text-align: center;
                    padding: 6px 20px;
                    background: #3974C6;
                    border-radius: 5px;
                    font-weight: 500;
                    font-size: 15px;
                    color: #FFFFFF;
                }
            }
        }

    }

    .imgBox {
        width: 100%;
        margin-top: 25px;
        display: flex;
        justify-content: center;

        img {
            width: 33px;
            height: 33px;
        }
    }
</style>