<template>
  <div class="BaiduMap">
    <baidu-map
      :scroll-wheel-zoom="true"
      :center="center"
      :zoom="zoom"
      @touchstart="mapmove"
      @touchend="mapTouchend"
      @touchmove="mapTouchmove"
      @ready="handler"
      ak="uSDQM0SXJgOKGoiDmMxh5a1BR0zhoKVJ"
      :dragging="dragging"
    >
      <bm-view
        v-show="['searchMap', 'map'].includes(customizedAttrs.type)"
        class="BaiduMap-map"
      />
      <bm-local-search
        v-show="['searchMap', 'search'].includes(customizedAttrs.type)"
        v-bind="customizedAttrs"
        v-on="$listeners"
      />

      <!-- 定位 -->
      <bm-geolocation
        anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
        :show-address-bar="true"
        :auto-location="true"
      />
      <bm-marker
        :position="center"
        :dragging="true"
        animation="BMAP_ANIMATION_BOUNCE"
      >
      </bm-marker>
      <!-- 在右上角加入缩放控件  -->
      <!-- <bm-navigation :enable-geolocation="true" anchor="BMAP_ANCHOR_BOTTOM_RIGHT" /> -->
    </baidu-map>
  </div>
</template>

  <script>
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmMarker from "vue-baidu-map/components/overlays/Marker.vue";
import bmInfoWindow from "vue-baidu-map/components/overlays/InfoWindow.vue";
import BmLocalSearch from "vue-baidu-map/components/search/LocalSearch.vue";
import BmCircle from "vue-baidu-map/components/overlays/Circle.vue";
import BmGeolocation from "vue-baidu-map/components/controls/Geolocation.vue";
import BmView from "vue-baidu-map/components/map/MapView"; //地图视图
import { getUserLocation } from "@/utils/wxCodeAuth";

// 参考文档：https://dafrok.github.io/vue-baidu-map/#/zh/search/local-search
export default {
  components: {
    BaiduMap,
    BmMarker,
    bmInfoWindow,
    BmLocalSearch,
    BmCircle,
    BmGeolocation,
    BmView,
  },
  name: "MyBaiduMap",
  // 不希望组件的根元素继承特性
  inheritAttrs: false,

  data() {
    return {
      //BMap类
      BMap: null,
      //地图对象
      map: null,
      center: { lng: 116.404, lat: 39.915 }, // 北京
      zoom: 3,
      zoom: 16,
      // 地图坐标解析器
      gc: "",
      address: "北京市东城区中华路甲10号",
      dragging: true,
    };
  },

  created() {
    //安卓设备需要 设置为false 否则点击事情无效
    var userAgent = navigator.userAgent;
    var isAndroid =
      userAgent.indexOf("Android") > -1 || userAgent.indexOf("Adr") > -1;
    if (isAndroid) {
      this.dragging = false;
    }
  },
  computed: {
    customizedAttrs() {
      const obj = {
        // type: 'searchMap', // searchMap 表示有搜索下拉框的地图 ； search 表示只有搜索下拉框;  map表示只有地图
        location: "深圳", // String, Point, None 	location表示检索区域，其类型可为空、坐标点或城市名称的字符串。当参数为空时，检索位置由当前地图中心点确定，且搜索结果的标注将自动加载到地图上，并支持调整地图视野层级；当参数为坐标时，检索位置由该点所在位置确定；当参数为城市名称时，检索会在该城市内进行。
        keyword: "附近", // String, Array 搜索关键字。当keyword为数组时将同时执行多关键字的查询，最多支持10个关键字
        selectFirstResult: false, // 是否选择第一个检索结果
        forceLocal: true, // 表示是否将搜索范围约束在当前城市
        panel: true, // 是否选展现检索结果面板
        pageCapacity: 3, // 设置每页容量，取值范围：1 - 100，对于多关键字检索，每页容量表示每个关键字返回结果的数量（例如当用2个关键字检索时，实际结果数量范围为：2 - 200）。此值只对下一次检索有效
        autoViewport: true, // 检索结束后是否自动调整地图视野。
      };
      const { type } = this.$attrs;
      // searchMap 表示有搜索下拉框的地图 ； search 表示只有搜索下拉框
      obj.type =
        type && ["searchMap", "search", "map"].includes(type)
          ? type
          : "searchMap";
      console.log("obj======>", obj);
      return Object.assign(obj, this.$attrs);
    },
  },
  methods: {
    async handler({ BMap, map }) {
      //获取用户位置
      const loctionInfo = await getUserLocation();
      this.center = await this.qqMapTransBdMap(
        loctionInfo.longitude,
        loctionInfo.latitude
      );
      this.zoom = 14;
      //创建地址解析器实例
      this.gc = new BMap.Geocoder();
      var pt = new BMap.Point(this.center.lng, this.center.lat);
      console.log('pt',pt)
      this.$emit("emitLngLat", pt);
      let that = this;
      this.map = map;
      this.map.addEventListener("click", function (e) {
        console.log(
          "点击了地图，点击位置经纬度为：",
          +e.point.lng + "," + e.point.lat
        );
        let emitData = e.point;
        that.$emit("emitLngLat", emitData);
      });
    },
    /**
     * @description 高德转百度
     * @description  GCJ-02 转 BD-09
     * @param{*}list [lng,lat]
     */
    qqMapTransBdMap(lng, lat) {
      let x_pi = (3.14159265358979324 * 3000.0) / 180.0;
      let x = lng;
      let y = lat;
      let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
      let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
      let lngs = z * Math.cos(theta) + 0.0065;
      let lats = z * Math.sin(theta) + 0.006;
      return { lng: lngs, lat: lats };
    },
    mapmove(e) {
      this.dragging = true;
    },
    //触摸移动时触发此事件
    mapTouchmove(e) {
      var userAgent = navigator.userAgent;
      var isAndroid =
        userAgent.indexOf("Android") > -1 || userAgent.indexOf("Adr") > -1;
      if (isAndroid) {
        this.dragging = true;
      } else {
        this.dragging = false; //开启禁止拖拽
      }
    },
    //触摸结束时触发此事件
    mapTouchend(e) {
      //安卓设备结束后需要 设置为false 否则点击事情无效
      var userAgent = navigator.userAgent;
      var isAndroid =
        userAgent.indexOf("Android") > -1 || userAgent.indexOf("Adr") > -1;
      if (isAndroid) {
        this.dragging = false;
      } else {
        this.dragging = true;
      }
    },
  },
};
</script>

  <style lang="less" scoped>
.BaiduMap {
  &-map {
    width: 100%;
    height: 400px;
  }
}
</style>